import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import toolformImage from "../images/tool_form.png";
import { HeaderSection, SectionBreak, InfoSection } from "../components/sections";

const LoginPage = () => (
    <Layout>
        <SEO title="Custom Tools" />
        <HeaderSection
            title="Custom Tools"
            subtitle="Integrate any UNIX command line tool into Galaxy Pro to join the thousands of already available tools."
        />
        <SectionBreak
            title="Be More Efficient with Select Tools"
            subtitle="Galaxy Pro can be customized with a custom toolbox to better match your data."
        />
        <InfoSection
            image={toolformImage}
            facts={[
                {
                    title: "Versatile Toolbox",
                    blurb: "The Galaxy Pro toolbox was crafted to include a versatile suite of options for analyzing data from raw FASTQ files to BAMs, VCFs, GFFs, etc. It is your selection.",
                },
                {
                    title: "Extensible with Your Tools",
                    blurb: "If you have an in-house tool, it is likely that it can be added into the existing Galaxy Pro toolbox with low effort. Just describe how it's used, and Galaxy Pro will automatically generate the web interface for it.",
                },
                {
                    title: "Popular Tools by Default",
                    blurb: "Tools automatically included represent best-of-breed, academically published tools that have been validated to work and are broadly accepted as the gold standard.",
                },
            ]}
        />
    </Layout>
);

export default LoginPage;
